<script setup>
    import { ref, defineProps, defineEmits } from 'vue'
    const { options } = defineProps(['options'])

    const selectedOption = ref(options[0]);

    const emit = defineEmits(['change'])

    const changeTab = (option) => {
        selectedOption.value = option;
        emit('change', option);
    }
</script>

<template>
    <ul>
        <li :class="{'active': selectedOption.value === option.value}" v-for="option in options" @click="changeTab(option)">
            {{ option.label }}
        </li>
    </ul>
</template>


<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background: $gray-thin_color;
        li {
            color: $gray_color;
            text-align: center;
            font-size: 18px;
            line-height: 30px;
            padding: 20px 0;
            span {
                display: inline-block;
                padding: 10px;
                vertical-align: middle;
            }
            &:hover, &.active {
                cursor: pointer;
                background: $blue_color;
                color: #fff;
            }
        }
    }
</style>