<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import consultationEvaluationService from '../../services/consultationEvaluationService'
    const router = useRouter()

    const consultation = ref(null)
    const testData = ref("")
    const btnName = ref("AI상담평가하기")

    const test_data = ref("write1")
    const test0 = ref("직접입력")
    const test1 = ref("보험료 청구서 관련 문의")
    const test2 = ref("쇼핑몰 불량접수")
    const test3 = ref("교통수단 문의")
    const test4 = ref("바이러스관련 문의")

    const consultationTokensView = ref(false)
    const consultationTokens = ref("0 tokens")
    const resultList = ref([])
    const resultTokens = ref("0 tokens")
    const resultTime = ref("0 ms")
    const result = ref("[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.")

    onMounted(()=>{
        testdata1();
    })

    let eval_flag = false;
    const ingString = '.....................................................................................';
    const txtDelay = 1000;
    let txtIndex = 0;
    let setTyping;
    function evalIng(){
        if(eval_flag === true){  // true일 경우 타이핑 진행, false일 경우 타이핑 중지
            let txtNow = ingString[txtIndex++];
            result.value += txtNow;
            if(txtIndex >= ingString.length){  // 문자열 index와 문자열 length를 비교해 문자열 마지막을 판별
                txtIndex = 0;  // 문자 입력을 다 하면 index를 0으로 초기화
            }
        }else{
            // typeControl이 false일 경우
            clearInterval(setTyping);  // setInterval 제거 (타이핑 멈춤)
        }
    }
    const evaluation = () => {

        let content = testData.value;
        if ( content === '' ) {
            alert('상담내용을 입력하세요');
            return false;
        }
        if ( eval_flag ) {
            alert('상담내용을 평가중입니다.');
            return false;
        }
        eval_flag = true;
        btnName.value = "평가중..";

        let p = testData.value;

        if ( result.value != '[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.' && !result.value.startsWith('평가중') ) {
            resultList.value.unshift({result: result.value, resultTime: resultTime.value, resultTokens: resultTokens.value});
        }

        // consultationTokens.value = "0 tokens";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        result.value = "평가중입니다....";

        setTyping = setInterval(evalIng, txtDelay);

        consultationEvaluationService.completions(p)
            .then(function (response) {
                eval_flag = false;
                btnName.value = "다른결과보기";
                //console.log(response);
                if ( response.status === 200 ) {

                    const responseResult = response.data;
                    const resultValue = responseResult.result.consultationEvaluation.trim();
                    if ( resultValue && resultValue != '' ) {
                        result.value = resultValue;
                        consultationTokensView.value = true;
                        consultationTokens.value = responseResult.result.promptTokens + " tokens";
                        resultTokens.value = responseResult.result.completionTokens + " tokens";
                        resultTime.value = responseResult.result.execTime + " ms";
                    } else {
                        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
                        consultationTokensView.value = false;
                        consultationTokens.value = "0 tokens";
                        resultTokens.value = "0 tokens";
                        resultTime.value = "0 ms";
                    }

                } else {
                    result.value = "평가에 실패하였습니다.";
                }
            })
            .catch(function (error) {
                eval_flag = false;
                btnName.value = "AI상담평가하기";
                console.log(error);
            });
        return false;
    }

    const testdata = () => {
        let testdata = '';
        testdata += "상담원 : 안녕하세요 고객님. 단비홈쇼핑 상담원 정정정 입니다. 무엇을 도와드릴까요?\n";
        testdata += "고객 : 내가 주문해서 받은 USB 허브의 포트 1개가 작동하지 않아요.\n";
        testdata += "상담원 : 먼저 고객 정보 확인부터 하겠습니다. 당당당 고객님 맞으신가요?\n";
        testdata += "고객 : 네 맞아요.\n";
        testdata += "상담원 : 확인해주셔서 감사합니다. 당당당님께서 12월 5일에 주문하신 usb허브 제품이 불량이라고 문의주셨는데요, 받으신 제품이 불량이어서 많이 속상하셨겠어요. 저희가 교환이나 환불로 도와드릴 수 있는데 어떤 것이 좋으실까요?\n";
        testdata += "고객 : 지금 교환환불이 중요 한게 아니고, 내가 오늘 필요해서 샀는데 정작 쓰려고 보니 안되서 아주 곤란했어요. 이거 어떻게 책임질꺼에요?\n";
        testdata += "상담원 : 불편을 드려 너무나 죄송합니다. 많이 곤란하셨겠어요. 어떻게 도와드리면 좋으실까요?\n";
        testdata += "고객 : 다른건 괜찮고 그러면 바로 환불해주세요.\n";
        testdata += "상담원 : 네 알겠습니다. 그러면 해당 주문에 대해 환불로 처리해드릴게요. 환불은 지금 바로 처리하도록 하겠습니다. 카드 환불은 카드사의 처리에 따라 최장 1주 일까지 소요될 수 있습니다. 제품은 다시 포장해서 문 밖에 내놔주시면 수거해가도록 하겠습니다. 또 다른 문의사항이 있으실까요?\n";
        testdata += "고객 : 아뇨 없어요.\n";
        testdata += "상담원 : 다시  한번 불편드린 점 사과의 말씀 드리겠습니다. 앞으로 이런 일이 재발하지 않도록 노력하는 현대홈쇼핑이 되도록 하겠습니다. 상담원 정정정이였습니다. 좋은 하루 되세요.\n";

        testData.value = testdata;
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
    }

    const testdata0 = () => {
        let testdata = '';
        testdata += "고객 : \n";
        testdata += "상담사 : \n";
        testdata += "고객 : \n";
        testdata += "상담사 : ";
        testData.value = testdata;

        let obj = consultation.value;
        if (obj.setSelectionRange) {
            obj.focus();
            obj.setSelectionRange(5, 5);
        } else if (obj.createTextRange) {
            var c = obj.createTextRange();
            c.move("character", 5);
            c.select();
        }

        consultationTokensView.value = false;
        consultationTokens.value = "0 tokens";
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
        return false;
    }
    const testdata1 = () => {
        let testdata = '';
        testdata += "고객 : 수술진단금 받을려고 문의 드렸는데 보험료 청구서를 못찾겠어요 어떻게 하면 될까요?\n";
        testdata += "상담사 : 보험료 청구서는 당사 홈페이지에서 찾으실수 있으세요\n";
        testdata += "고객 : 홈페이지 어느 부분에 있나요?\n";
        testdata += "상담사 : 지금 홈페이지 접속해 있으신가요?\n";
        testdata += "고객 : 네 홈페이지 보고 있어요\n";
        testdata += "상담사 : 홈페이지 왼쪽 하단에 보시면 구비서류 안내 양식이 있습니다.\n";
        testdata += "고객 : 네 찾았어요\n";
        testdata += "상담사 : 사고 보험금 청구에 클릭 한번 해주세요\n";
        testdata += "고객 : 클릭했어요\n";
        testdata += "상담사 : 이용안내 서류안내 서류다운로드가 보이시나요?\n";
        testdata += "고객 : 네 보입니다.\n";
        testdata += "상담사 : 서류다운로드를 클릭 후 서식양식 다운로드하셔서 출력하시면 됩니다.\n";
        testdata += "고객 : 알려주셔셔 감사합니다.\n";
        testdata += "상담사 : 작성예시 다운로드 하셔서 참고하시면 누락부분 없이 작성하실 수 있습니다.\n";
        testdata += "고객 : 참고 하겠습니다.\n";
        testdata += "고객 : 보험금 청구서외 필요한 서류들을 한번 더 문자로 보내주실수 있나요\n";
        testdata += "상담사 : 네 보험료 청구시 필요한 서류들을 안내문자로 발송해 드리겠습니다.\n";
        testdata += "고객 : 문자 받았습니다.\n";
        testdata += "상담사 : 궁금하신 부분이 더 있으신가요?\n";
        testdata += "고객 : 아니요 감사합니다.\n";

        testData.value = testdata;
        consultationTokensView.value = false;
        consultationTokens.value = "0 tokens";
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
    }

    const testdata2 = () => {
        let testdata = '';
        testdata += "상담사 : 네, ㅇㅇㅇ쇼핑입니다. 무엇을 도와 드릴까요?\n";
        testdata += "고객 : 제가 물건을 세개 샀거든요.\n";
        testdata += "상담사 : 고객님 말씀 중에 죄송합니다. 제가 고객님 정보 확인 후에 안내 도와드리도록 하겠습니다.\n";
        testdata += "고객 : 아 네.\n";
        testdata += "상담사 : 전화 주신 고객님의 성함과 연락처 확인 부탁 드립니다.\n";
        testdata += "고객 : 네, ㅇㅇㅇ이고, ㅇㅇㅇ ㅇㅇㅇㅇ ㅇㅇㅇㅇ입니다.\n";
        testdata += "상담사 : 네, 소중한 확인 감사합니다. 제습기 위니아 가정용 주문한 것 확인되어서 총 세개네요.\n";
        testdata += "고객 : 네, 마지막에 주문한 거 한개짜리 있죠? 그게 하루만에 전원이 안 들어와요.\n";
        testdata += "상담사 : 아 그러십니까?\n";
        testdata += "고객 : 네, 그쪽에서 교환을 좀 해주셔야 될 것 같은데요. 지금 기사님이 나와계세요.\n";
        testdata += "상담사 : 죄송하지만 이거 불량 판정서 주실 수 있습니까?\n";
        testdata += "고객 : 네, 불량 판정서 보내 드릴게요.\n";
        testdata += "상담사 : 네, 그럼 불량 판정서 끊어 주시면 저희도 이제 판매자 측하고 확인해 가지고 처리는 도와드릴 수 있습니다.\n";
        testdata += "고객 : 불량 판정서를 어디로 넣어 드리면 될까요?\n";
        testdata += "상담사 : 업체에 직접 전달해 드리면 좋습니다.\n";
        testdata += "고객 : 네, 그럼 팩스로 직접 넣어 드릴께요.\n";
        testdata += "상담사 : 네, 그러면 업체에다가 저도 불량 판정서를 확인해 가지고 확인 받을 때 팩스 번호나 이메일 주소 확인해가지고 기사님한테 제가 문자로 남겨드려도 되겠습니까?\n";
        testdata += "고객 : 네, 알겠습니다.\n";
        testdata += "상담사 : 네, 더 문의할 사항은 없으십니까?\n";
        testdata += "고객 : 네, 없어요.\n";
        testdata += "상담사 : 네, 저는 ㅇㅇㅇ이었습니다.\n";
        testdata += "고객 : 네, 수고하세요.\n";

        testData.value = testdata;
        consultationTokensView.value = false;
        consultationTokens.value = "0 tokens";
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
    }

    const testdata3 = () => {
        let testdata = '';
        testdata += "고객 : 전주에서 제주도가는 배편 문의합니다.\n";
        testdata += "상담사 : 전주에서 목포항, 여수항 등 이동하여 제주도가는 배를 이용하실 수 있습니다.\n";
        testdata += "고객 : 전주에서 목포항가려면 뭐가 빨라요?\n";
        testdata += "상담사 : 대중교통 안내해드릴까요?\n";
        testdata += "고객 : 네. 알려주세요.\n";
        testdata += "상담사 : 전주역-익산역-목포역으로 기차로 환승하시는게 제일 빠릅니다.\n";
        testdata += "고객 : 목포항에서 출발하는 배는 몇신데요?\n";
        testdata += "상담사 : 오전9시, 오후 1시입니다.\n";
        testdata += "고객 : 전주에서 목포까지 기차로 얼마나 걸려요?\n";
        testdata += "상담사 : 약 1시간 40분정도 소요됩니다. 기차에 따라 소요시간이 더 걸릴 수 있습니다.\n";
        testdata += "고객 : 목포역에서 목포항까지는 차로 몇분걸려요?\n";
        testdata += "상담사 : 차로 약 15분정도 걸립니다.\n";
        testdata += "고객 : 금액은요?\n";
        testdata += "상담사 : 3,300원입니다.\n";
        testdata += "고객 : 전주에서 익산가는 첫차가 몇시죠?\n";
        testdata += "상담사 : 오전 6시27분입니다.\n";
        testdata += "고객 : 아 그럼 충분히 갈 수 있겠네요.\n";
        testdata += "상담사 : 더 궁금하신 사항 있으신가요?\n";
        testdata += "고객 : 아니요. 없어요.\n";
        testdata += "상담사 : 네. 좋은 하루 되십시오.\n";

        testData.value = testdata;
        consultationTokensView.value = false;
        consultationTokens.value = "0 tokens";
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
    }

    const testdata4 = () => {
        let testdata = '';
        testdata += "고객 : 코로나 바이러스  일상 생활 속 궁금한 점이 있어요\n";
        testdata += "상담사 : 예 궁금하신 사항을  말씀해 주세요.\n";
        testdata += "고객 : 공기 중에서 코로나 바이러스가 생존하는시간이 얼마나될까요?\n";
        testdata += "상담사 : 바이러스는 환경에 따라 생존하는 시간이 다르기 때문에 사실 잘 모릅니다.\n";
        testdata += "고객 : 소독제로 소멸은 가능한가요?\n";
        testdata += "상담사 : 예 확실한 것은 소독제에는 매우 약하니 손소독만 잘 하시면 됩니다.\n";
        testdata += "고객 : 재채기를 할때는 어디에 하는건가요?\n";
        testdata += "상담사 : 옷소매에 하시면 됩니다.\n";
        testdata += "고객 : 외출하고 돌아왔을때 옷이나 이런곳에 묻어 있는 바이러스는 얼마나 생존 하나요?\n";
        testdata += "상담사 : 그것도 잘 알수 없습니다\n";
        testdata += "고객 : 마스크를 하고 대중 교통을 이용할때 감염될 확률이 있을까요?\n";
        testdata += "상담사 : 수치로 말씀드리긴 어렵지만 항상 손을 자주 씻고 마스크를 올바르게 써야합니다.\n";
        testdata += "고객 : 신종 코로나 바이러스 환자와 한 공간에 있었지만 대화를 하거나 접촉하지 않았다고 했을 때도 감염될수 있나요?\n";
        testdata += "상담사 : 그런경우라도 숫자나 데이터는 모르는게 많지만 대형 교회에서 2시간 예배 보았을 때 감염된 것으로 봐서 완벽하진 않습니다.\n";
        testdata += "고객 : 같은 비행기를 이용한 사람도 확진자가 나왔는데 어떤가요?\n";
        testdata += "상담사 : 공기가 순환이 안되는 상황이라 사스나 메르스 때 보면 자리에서 1미터 이내가 더 위험합니다.\n";
        testdata += "고객 : 접촉의 기준은 무엇인가요?\n";
        testdata += "상담사 : 정확히 말씀드릴순 없지만 1미터 이내 15분 이상 신체접촉했나 이정도로 역학조사관이 조사를 합니다\n";
        testdata += "고객 : 확진자와 함께 있었던 상황이라면 조사관에게 잘 알려야 되나요?\n";
        testdata += "상담사 : 변수도 많은 터라 모든 상황을 역학 조사관에게 정확하게 전달하는 것이 중요합니다.\n";

        testData.value = testdata;
        consultationTokensView.value = false;
        consultationTokens.value = "0 tokens";
        btnName.value = "AI상담평가하기";
        result.value = "[AI상담평가하기]를 클릭하면 결과가 여기에 생성됩니다.";
        resultTokens.value = "0 tokens";
        resultTime.value = "0 ms";
        resultList.value = [];
    }

</script>

<template>
    <div class="main">
        <h1>상담평가 데모</h1>
        <!--
        <ul>
          <li>기본 모델은 20만건 이상의 다양한 도메인의 데이터를 학습하였습니다. <strong>즉시 API 연동 또는 서버에 설치</strong>하여 사용하실 수 있습니다. </li>
          <li>고객사가 보유한 상담 대화 데이터와 요약 데이터가 있으신가요? <strong>학습을 통해 기업 특성에 맞게 커스터마이즈</strong> 가능합니다.</li>
          <li>고객사 IT인프라에 설치하여 활용하실 수 있어 <strong>엔터프라이즈 환경에 맞는 보안과 예측 가능한 예산</strong>으로 운영하실 수 있습니다.</li>
        </ul>
        -->
        <div>
            <p>
              상담내용을 선택하거나 입력 후 [AI상담평가하기]를 클릭하세요.
            </p>
            <div class="btn-wrap">
                <RadioButton name="test_data" :label="test0" @click="testdata0()" value="write" />
                <RadioButton name="test_data" :label="test1" @click="testdata1()" value="write1" :modelValue="test_data"/>
                <RadioButton name="test_data" :label="test2" @click="testdata2()" value="write2" />
                <RadioButton name="test_data" :label="test3" @click="testdata3()" value="write3" />
                <RadioButton name="test_data" :label="test4" @click="testdata4()" value="write4" />
            </div>
            <textarea ref="consultation" id="consultation" rows="10" style="width:100%" spellcheck="false" v-model="testData"></textarea>
            <div class="text-information">
                <span id="consultationTokens" v-show="consultationTokensView">{{ consultationTokens }}</span>
            </div>
        </div>
        <div class="btn-wrap">
            <PrimaryButton :text="btnName" @click="evaluation()" />
        </div>
        <div>
            <div class="result" v-html="result"></div>
            <div class="text-information">
                <span id="resultTime">{{ resultTime }}</span>
                <span id="resultTokens">{{ resultTokens }}</span>
                <br>
            </div>
        </div>
        <div v-for="(resultData, index) in resultList">
            <div class="result" v-html="resultData.result"></div>
            <div class="text-information">
                <span>[{{ resultList.length - index }}] </span>
                <span>{{ resultData.resultTime }}</span>
                <span>{{ resultData.resultTokens }}</span>
                <br>
            </div>
        </div>
        <div class="text-information">
            <span>상담 평가 결과는 최대 500토큰까지만 표시됩니다. 상담 평가의 형식, 평가 템플릿, 기타 텍스트 기반의 생성형 모델을 기업 맞춤 학습을 통해 제공이 가능합니다.</span>
        </div>
        <br><br><br><br><br><br><br>
    </div>

  <Modal />

</template>

<style lang="scss">

body {
    text-align: left;
}

#logo-autowriting {
    display:inline-block;
    margin: 10px 80px;
}
.main {
    padding:0 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    text-align: left;
    top: 50%;
    left: 0;
    transform: translate3d(0, 0, 0);
    font-family: sans-serif;
    div {
        margin: 10px 0 0 0;
        h2 {
            line-height: 40px;
        }
        p {
            text-align:left;
        }
        ul {
            margin-top:10px;
            li {
                font-size:14px;
                list-style: initial;
                margin-left:20px;
                line-height:18px;
                margin-bottom:10px;
            }
        }
        textarea {
            font-size:14px;
            padding:10px;
            border:1px solid #c9c5bb;
        }
        .result {
            font-size:14px;
            border: 1px solid #c9c5bb;
            background: #fffef7;
            padding:10px;
            margin:0;
            text-align:left;
        }
    }
    button {
        margin: 0 5px;
    }
    .btn-wrap {
        margin: 10px 0;
        text-align:left;
        label, input {
            display:inline-block;
            margin-right: 10px;
        }
    }
    .text-information {
        margin-top:4px;
        margin-bottom:8px;
        text-align: left;
        span {
            display:inline-block;
            margin-right:10px;
            font-style: italic;
            color: #9bcfff;
        }
    }
}

@media (orientation: portrait) and (max-width: 640px) {
    body {

    }
    #logo-autowriting {
        display:inline-block;
        margin: 10px 10px;
    }
    .main {
        padding:0 10px;
        div {
            margin:12px 0px 18px 0;
        }
        .btn-wrap {
            label {
                display:block;
            }
        }
        textarea {
            height:120px;
        }
    }
}
</style>
