import http from '../api/http';

function imageToImage(formData) {

    return http.post('/imageGen/imageToImage', formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export default {
    imageToImage,
};