<script setup>
    import { computed, defineEmits, toRefs } from "vue";

    const props = defineProps({
        modelValue: { type: String },
        label: { type: String },
        placeholder: { type: String },
        maxLength: {
            type: String,
        },
    });

    const { modelValue, label, placeholder, maxLength } = toRefs(props);

    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });
</script>

<template>
    <div>
        <p v-if="label">{{ label }}</p>
        <textarea :value="modelValue" @input="(event) => emit('update:modelValue', event.target.value)" :placeholder="placeholder" :maxlength="maxLength"/><br/>
        <span v-if="maxLength !== undefined">{{ model.length }}자<i>/{{ maxLength }}자</i></span>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    p {
        font-size: 14px;
        line-height: 24px;
        color: $gray_color;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        height: 100%;
        border: $border_line;
        padding: 10px 12px;
        &::placeholder {
            color: $gray_color;
        }
    }
    span {
        display: block;
        text-align: right;
        i {
            font-style: normal;
            color: $gray_color;
        }
    }

    .background-textarea {
        textarea {
            border: 0;
            background: #F0F0F0;
            border-radius: 10px;
        }
    }
</style>