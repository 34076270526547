<template>
    <Modal ref="baseModal">
        <div class="modal-inner image-modal">
            <div>
                <img :src="imgSrc" />
            </div>
            <div class="btn-wrap">
                <PrimaryButton text="확인" @click="close" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import { ref } from "vue";

export default {
    name: "ImageModal",
    components: {
        Modal,
    },
    // 렌더링할 텍스트를 가져옵니다.
    props: {
        content: Array,
    },
    setup() {
        const baseModal = ref(null);
        const resolvePromise = ref(null);

        const imgSrc = ref('')

        const show = () => {
            baseModal.value.open();

            return new Promise((resolve, _) => {
                // resolve 함수를 담아 외부에서 사용합니다.
                resolvePromise.value = resolve;
            });
        };

        const close = () => {
            baseModal.value.close();
            resolvePromise.value(true);
        };

        const setImgSrc = (src) => {
            imgSrc.value = src;
        }

        return { baseModal, show, close, imgSrc, setImgSrc };
    },
};
</script>


<style lang="scss" scoped>
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    .btn-wrap {
      margin: 20px 0 0;
      button {
        width: 100%;
      }
    }
</style>