<script setup>
    import { defineProps, defineEmits } from 'vue'
    const { text, color, disabled } = defineProps(['text', 'color', 'disabled'])

    const emit = defineEmits(['click'])

    const emitClick = () => {
        emit('click')
    }
</script>

<template>
    <button @click="emitClick" :disabled="disabled" :style="color && {'background-color': color}">{{ text }}</button>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    button {
        height: 48px;
        border:none;
        cursor:pointer;
        border-radius: 4px;
        padding: 10px 20px;
        background: $blue_color;
        color: #fff;
        &.black_button {
            background: $black_color;
            color: #fff;
        }
        &:disabled {
            background: $gray-light_color;
            color: $gray-regular_color;
        }
    }
</style>