<script setup>
    import { ref, onMounted, onUnmounted, computed, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import imageGenerationService from '../../services/imageGenerationService'
    const router = useRouter()


    const test0 = ref("만화 캐릭터1")
    const test1 = ref("만화 캐릭터2")
    const test2 = ref("만화 캐릭터3")
    const btnName = ref("사진찍기")
    const btnName1 = ref("이미지생성")
    const videoRecodingFlag = ref(false)
    const recodingFinish = ref(false)
    const genFlag = ref(false)
    const write = ref("write0")

    const facevideo = ref(null)
    const facecanvas = ref(null)
    const resizecanvas = ref(null)
    const recogIngMsg = ref("")
    const result = ref("")

    const positivePrompt = ref("Disney Pixar style, 4k, 8k, unreal engine, octane render photorealistic by cosmicwonder, hdr, photography by cosmicwonder, high definition, symmetrical face, volumetric lighting, dusty haze, photo, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic")
    const negativePrompt = ref("ugly, deformed, disfigured")
    const genStyle = ref("3d-model")


    const windowWidth = ref(window.innerWidth)
    const windowHeight = ref(window.innerHeight)

    const videoWidth = ref(640)
    const videoHeight = ref(480)

    const handleResize = () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
        if (windowWidth.value < 700) {
            videoWidth.value = 320;
            videoHeight.value = 240;
        } else {
            videoWidth.value = 640;
            videoHeight.value = 480;
        }
    }

    onUnmounted(() => {
        window.removeEventListener('resize', handleResize)
    })

    onMounted(()=>{
        window.addEventListener('resize', handleResize);

        if ( window.innerWidth < 700 ) {
            videoWidth.value = 320;
            videoHeight.value = 240;
        }

        const video = facevideo.value;
        console.log(video)
        const mediaStream = new MediaStream();
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    width: {min: 320, max: 640},
                    height: {min: 240, max: 480},
                    facingMode: 'user'
                }
            }).then(stream => {
                video.srcObject = stream;
                //video.src = URL.createObjectURL(stream);
                video.play();
                videoRecodingFlag.value = true;
            });
        }
    })

    const getPicture = () => {

        videoRecodingFlag.value = true;
        recodingFinish.value = false;
        result.value = "";
        btnName.value = "찍는중";
        recogIngMsg.value = "3";

        setTimeout(()=>{
            recogIngMsg.value = '2';
        },1000);

        setTimeout(()=>{
            recogIngMsg.value = '1';
        },2000);

        setTimeout(()=>{
            const canvas = facecanvas.value;
            canvas.width = videoWidth.value;
            canvas.height = videoHeight.value;
            const rcanvas = resizecanvas.value;
            rcanvas.width = 1152;
            rcanvas.height = 896;
            const context = canvas.getContext('2d');
            const resizecontext = rcanvas.getContext('2d');

            const video = facevideo.value;
            //console.log(video);

            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            resizecontext.drawImage(video, 0, 0, 1152, 896);
            videoRecodingFlag.value = false;
            recodingFinish.value = true;
            btnName.value = '다시 찍기';
            recogIngMsg.value = '찰칵!';

        },3000);
        setTimeout(()=>{
            recogIngMsg.value = '';
        },4000);
        return false;
    }

    const imageGen = () => {

        if ( genFlag.value ) {
            alert('이미지를 생성중입니다.')
            return false;
        }
        genFlag.value = true;
        btnName1.value = '생성중..';

        const rcanvas = resizecanvas.value;
        const img = rcanvas.toDataURL('image/jpeg');;

        var blobBin = atob(img.split(',')[1]);	// base64 데이터 디코딩
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});	// Blob 생성
        //console.log(file);

        let formData = new FormData();
        formData.append("initFile", file);
        formData.append("positivePrompt", positivePrompt.value);
        formData.append("negativePrompt", negativePrompt.value);
        formData.append("stylePreset", genStyle.value);
        formData.append("imageStrength", 0.50);
        formData.append("cfgScale", 27.0);

        imageGenerationService.imageToImage(formData)
            .then(function (response) {
                console.log(response);
                if ( response.status === 200 ) {

                    const responseResult = response.data;
                    if (responseResult.code == "200") {
                        console.log(responseResult.result.outputFile);
                        result.value = responseResult.result.outputFile;
                    } else {
                        console.log(responseResult.result.resultMsg);
                        result.value = responseResult.result.resultMsg;
                    }

                } else {
                    const responseResult = response.data;
                    console.log(responseResult.msg);
                    result.value = responseResult.msg;
                }
                btnName.value = "사진찍기";
                btnName1.value = '이미지생성';
                recogIngMsg.value = '';

                genFlag.value = false;
                recodingFinish.value = false;
                videoRecodingFlag.value = true;
            })
            .catch(function (error) {
                btnName.value = "사진찍기";
                btnName1.value = '이미지생성';
                recogIngMsg.value = '';
                genFlag.value = false;
                recodingFinish.value = false;
                videoRecodingFlag.value = true;
                console.log(error);
            });
        return false;
    }

    const setPrompt = (prompt) => {
        if ( prompt === 'write0' ) {
            positivePrompt.value = "Disney Pixar style, 4k, 8k, unreal engine, octane render photorealistic by cosmicwonder, hdr, photography by cosmicwonder, high definition, symmetrical face, volumetric lighting, dusty haze, photo, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic"
            negativePrompt.value = "ugly, deformed, disfigured"
            genStyle.value = "3d-model"
        } else if ( prompt === 'write1' ) {
            positivePrompt.value = "pltn style, draw I started working, artist table brushes drawing, making a cartoon, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail"
            negativePrompt.value = "double face, freak, scary beautiful woman in a trench with binoculars, pit hands"
            genStyle.value = "anime"
        } else if ( prompt === 'write2' ) {
            positivePrompt.value = "modern disney style, Pixar render, unreal engine cinematic smooth, intricate detail"
            negativePrompt.value = "ugly, deformed, disfigured"
            genStyle.value = "anime"
        } else {
            positivePrompt.value = "Disney Pixar style, 4k, 8k, unreal engine, octane render photorealistic by cosmicwonder, hdr, photography by cosmicwonder, high definition, symmetrical face, volumetric lighting, dusty haze, photo, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic"
            negativePrompt.value = "ugly, deformed, disfigured"
            genStyle.value = "3d-model"
        }
    }

</script>

<template>
    <div class="main">
        <h1>이미지생성 데모</h1>
        <div>
            <p>
              상담내용을 선택하거나 입력 후 [AI상담요약하기]를 클릭하세요. {{windowWidth}}, {{windowHeight}}
            </p>
            <div class="btn-wrap">
                <RadioButton name="test_data" :label="test0" value="write0" :modelValue="write" @click="setPrompt('write0')" />
                <RadioButton name="test_data" :label="test1" value="write1" :modelValue="write" @click="setPrompt('write1')"/>
                <RadioButton name="test_data" :label="test1" value="write2" :modelValue="write" @click="setPrompt('write2')"/>
            </div>
            <div class="camera-wrapper">
                <span id="" class="recog-ingtext">{{ recogIngMsg }}</span>
                <video ref="facevideo" id="video" :width="videoWidth" :height="videoHeight" autoplay playsinline v-show="videoRecodingFlag" style="object-fit: cover;"></video>
                <canvas ref="facecanvas" id="canvas" :width="videoWidth" :height="videoHeight" class="main-image " v-show="!videoRecodingFlag"></canvas>
                <canvas ref="resizecanvas" id="resizecanvas" width="1152" height="896" class="main-image" v-show="false"></canvas>
            </div>
            <div class="text-information">
                <span id="consultationTokens" v-show="consultationTokensView">{{ consultationTokens }}</span>
            </div>
        </div>
        <div class="btn-wrap">
            <PrimaryButton :text="btnName" @click="getPicture()" v-show="!genFlag" />
            <PrimaryButton :text="btnName1" @click="imageGen()" v-show="recodingFinish"/>
        </div>
        <div>
            <div class="result" v-if="result != ''">
                <a :href="result" target="_blank" v-if="result.startsWith('http')"><img class="selected" :src="result" :style="{'width': videoWidth+'px', 'height': videoHeight+'px'}"></a>
                <span v-if="!result.startsWith('http')">{{result}}</span>
            </div>
        </div>
        <div class="text-information">
            <span> {{test0}} 스타일의 이미지를 생성합니다.</span>
        </div>
        <br><br><br><br><br><br><br>
    </div>

    <Modal />

</template>

<style lang="scss">

body {
    text-align: left;
}

canvas, video {
    display: inline-block;
    vertical-align: baseline;
}

#logo-autowriting{
    display:inline-block;
    margin: 10px 80px;
}
.main {
    padding:0 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    text-align: left;
    top: 50%;
    left: 0;
    transform: translate3d(0, 0, 0);
    font-family: sans-serif;
    div {
        margin: 10px 0 0 0;
        h2 {
            line-height: 40px;
        }
        p {
            text-align:left;
        }
        ul {
            margin-top:10px;
            li {
                font-size:14px;
                list-style: initial;
                margin-left:20px;
                line-height:18px;
                margin-bottom:10px;
            }
        }
        .camera-wrapper {
            max-width: 99.99%;
            height: 100%;
            position: relative;
            .recog-ingtext{
                position: absolute;
                background: 0;
                color: #fff;
                margin: auto;
                font-size: 160px;
                opacity: 0.3;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                text-align: center;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                z-index: 1000;
                pointer-events: none;
            }
        }
    }
    button {
        margin: 0 5px;
    }
    .btn-wrap {
        margin: 10px 0;
        text-align:left;
        label, input {
            display:inline-block;
            margin-right: 10px;
        }
    }
}

@media (orientation: portrait) and (max-width: 640px) {
    body {

    }
    #logo-autowriting {
        display:inline-block;
        margin: 10px 10px;
    }
    .main {
        padding:0 10px;
        div {
            margin:12px 0px 18px 0;
        }
        .btn-wrap {
            label {
                display:block;
            }
        }
        textarea {
            height:120px;
        }
    }
}

</style>
