<script setup>
    import { ref, computed } from 'vue'
    import { RouterLink } from 'vue-router'
    import { useUserStore } from "../stores/user";
    import { useRoute, useRouter } from 'vue-router'

    const { user, setUser } = useUserStore();
    const userStore = useUserStore();
    const router = useRouter()

</script>

<template>
    <header>
        <a href="https://autowriting.ai/" id="logo-autowriting" title="소개페이지로 돌아가기"><img src="/src/assets/images/logo_autowriting.svg"></a>
    </header>
</template>

<style lang="scss" scoped>
    @import "../assets/scss/theme";
    .header {
        padding: 20px 0;
    }
    @media (max-width: 480px) {
        .header {

        }
    }

</style>