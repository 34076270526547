<script setup>
    import { ref, defineProps, defineEmits, defineExpose, computed } from 'vue'
    const { options, selected } = defineProps(['options', 'selected']);

    const isObject = ref(typeof(options[0]) === 'object')
    const _selected = ref(selected || options[0])
    const open = ref(false);

    const _selectedLabel = computed(()=>{
        return isObject.value ? _selected.value.label : _selected.value;
    })

    const emit = defineEmits(['selectOption']);

    const emitSelectedOption = (option) => {
        open.value = false;
        _selected.value = option;
        emit("selectOption", option);
    }

    const initOptions = (value) => {
        _selected.value = value;
    }

    defineExpose({
        initOptions,
    })
</script>

<template>
    <div class="custom-select" @blur="open = false">
        <div class="selected" :class="{ open }" @click="open = !open">
            {{ _selectedLabel }}
        </div>
        <div class="items" :class="{'selectHide': !open }">
            <div v-for="option in options" @click="emitSelectedOption(option);">
                {{ isObject ? option.label : option }}
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
@import "../../../assets/scss/ui-components/select";
</style>
