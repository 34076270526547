import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from "vue-i18n";
import App from './App.vue'
import router from '../router'
import mitt from 'mitt'

import '../assets/fonts/fonts.css'
import '../assets/main.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import messages from "@intlify/unplugin-vue-i18n/messages";

import Checkbox from '../components/ui-components/Checkbox.vue'
import Datepicker from '../components/ui-components/Datepicker.vue'
import FormEmail from '../components/ui-components/FormEmail.vue'
import Image from '../components/ui-components/Image.vue'
import RadioButton from '../components/ui-components/RadioButton.vue'
import SearchInputBox from '../components/ui-components/SearchInputBox.vue'
import Tab from '../components/ui-components/Tab.vue'
import Textarea from '../components/ui-components/Textarea.vue'
import AlertModal from '../components/ui-components/modal/AlertModal.vue'
import ConfirmModal from '../components/ui-components/modal/ConfirmModal.vue'
import ImageModal from '../components/ui-components/modal/ImageModal.vue'
import Paginate from '../components/ui-components/pagination/Paginate.vue'
import Select  from '../components/ui-components/select/Select.vue'
import TextButton  from '../components/ui-components/button/TextButton.vue'
import PrimaryButton  from '../components/ui-components/button/PrimaryButton.vue'
import SecondaryButton  from '../components/ui-components/button/SecondaryButton.vue'
import Input  from '../components/ui-components/input/Input.vue'
import InputPhoneNum  from '../components/ui-components/input/InputPhoneNum.vue'

import common from '../plugins/common'
import themecolor from '../plugins/themecolor'
import toast from '../plugins/toast'

const app = createApp(App)

const emitter = mitt();
app.config.globalProperties.emitter = emitter

app.use(router)
app.use(createPinia())
app.use(common);
app.use(themecolor);
app.use(toast);

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: navigator.language || "ko",
    fallbackLocale: navigator.language || "ko",
    availableLocales: ["en", "ko"],
    messages: messages,
});

app.use(i18n)

app
    .component('Checkbox', Checkbox)
    .component('Datepicker', Datepicker)
    .component('FormEmail', FormEmail)
    .component('Image', Image)
    .component('RadioButton', RadioButton)
    .component('SearchInputBox', SearchInputBox)
    .component('Tab', Tab)
    .component('TextArea', Textarea)
    .component('AlertModal', AlertModal)
    .component('ConfirmModal', ConfirmModal)
    .component('ImageModal', ImageModal)
    .component('Paginate', Paginate)
    .component('Select', Select)
    .component('TextButton', TextButton)
    .component('PrimaryButton', PrimaryButton)
    .component('SecondaryButton', SecondaryButton)
    .component('Input', Input)
    .component('InputPhoneNum', InputPhoneNum)

app.mount('#app')
