
<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import { useRoute, RouterView } from 'vue-router'
    import ContactModal from '../pages/ConsultationSummary/ContactModal.vue'

    const route = useRoute()
    const modal = ref(null)
    const btnCTA = ref("오토라이팅AI 문의하기")

    const onClickContact = async () => {
        const ok = await modal.value.show()
        if(ok){
            alert("접수되었습니다.");
        }
    }
    const isWorkspaceRoute = computed(()=>{
        return route && route.name && ['ImageGeneration'].indexOf(route.name) === -1;
    })
</script>

<template>
    <footer class="footer">
        <div class="inner" v-if="isWorkspaceRoute">
            <span class="msg-cta">생성형 AI기반의 상담 내용 요약 및 평가, 오토라이팅AI에 대해 더 알고 싶으신가요? &nbsp;</span>
            <PrimaryButton :text="btnCTA" @click="onClickContact" />
        </div>
        <ContactModal ref="modal" />
    </footer>
</template>

<style lang="scss" scoped>
    @import "../assets/scss/theme";
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #494B5B;
        color: #fff;
        padding: 20px 0;
        text-align: center;
        .logo {
            text-align: center;
            color: $gray-bold_color;
        }
    }
    @media (orientation: portrait) and (max-width: 640px) {
    .msg-cta{display:none;}
    }
</style>