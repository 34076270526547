import http from '../api/http';

function completions(content) {

    return http.post('/consultationevaluation/completions', {
        consultationLog: content
    });
}

function contactUs(contact) {

    return http.post('/contact/us', contact);
}

export default {
    completions,
    contactUs,
};