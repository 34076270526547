// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive, watch } from 'vue'

export const useUserStore = defineStore('user', () => {
    const user = reactive({
        apiKey: '',
        userGb: '',
        userId: '',
        email: '',
        nickname: '',
        token: '',
        refreshToken: '',
    })

    const userInStorage = localStorage.getItem('user')
    if (userInStorage) {
        const { apiKey, userGb, userId, email, nickname, token, refreshToken } = JSON.parse(userInStorage)
        user.apiKey = apiKey
        user.userGb = userGb
        user.userId = userId
        user.email = email
        user.nickname = nickname
        user.token = token
        user.refreshToken = refreshToken
    }

    watch(() => user, (state) => {
        localStorage.setItem('user', JSON.stringify(state))
    }, { deep: true })

    // setUser simple function
    function setUser ({ apiKey, userGb, userId, email, nickname, token, refreshToken }) {
        user.apiKey = apiKey;
        user.userGb = userGb;
        user.userId = userId;
        user.email = email;
        user.nickname = nickname;
        user.token = token;
        user.refreshToken = refreshToken;
    }

    function setToken(token) {
        user.token = token;
    }

    function setRefreshToken(token) {
        user.refreshToken = token;
    }

    return {
        user,
        setUser,
        setToken,
        setRefreshToken
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
