<script setup >
    import { computed, toRefs, defineEmits, defineExpose } from "vue";
    const props = defineProps({
        modelValue: { type: [Array, Boolean] },
        value: { type: [Boolean, Object] },
        label: { type: String },
        name: { type: String },
        disabled: { type: Boolean, default: false, },
    });

    const { value } = toRefs(props);

    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });


    const init = (v) => {
        value.value = v;
    }

    defineExpose({
        init,
    })
</script>

<template>
    <label class="check-box">
        <p class="label">{{ label }}</p>
        <input type="checkbox" :name="name" v-model="model" :value="value" :disabled="disabled"/>
        <span class="checkmark"></span>
    </label>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .check-box {
        display: flex;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .label {
            display: inline;
            color: $black_color;
            margin: 0;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: $blue_color;
            }
            &:checked ~ .checkmark:after {
                display: block;
            }
        }
        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 20px;
            width: 20px;
            border: $border_line;
            background-color: #fff;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 4px;
                width: 10px;
                height: 10px;
                background: url(../../assets/images/chck-mark.png) no-repeat center center;
            }
        }
    }

</style>