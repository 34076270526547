<script setup>
    import { computed } from 'vue'
    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Loading from 'vue3-loading-overlay';
    import { usePageLoadingStore } from '../stores/pageLoading'

    const pageLoadingStore = usePageLoadingStore()

    const isLoading = computed(()=>{
        return pageLoadingStore.loading;
    })

</script>

<template>
    <DefaultLayout>
        <loading :active="isLoading"
                 loader="dots"
                 color="#329CFF"
                 opacity="0.4"
                 width="50"
                 :is-full-page="true"></loading>
        <RouterView/>
    </DefaultLayout>
</template>
