import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../stores/user'

import HomeView from '../pages/HomeView.vue'
import ConsultationSummaryView from '../pages/ConsultationSummary/View.vue'
import ConsultationEvaluationView from '../pages/ConsultationEval/View.vue'
import ImageGenerationView from '../pages/ImageGeneration/View.vue'
import RouterView from '../components/RouterView.vue'
import NotFound from '../pages/NotFound.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: ConsultationSummaryView,
    },
    {
      path: "/ConsultationSummary",
      name: "ConsultationSummary",
      component: ConsultationSummaryView,
    },
    {
      path: "/ConsultationEvaluation",
      name: "ConsultationEvaluation",
      component: ConsultationEvaluationView,
    },
    {
      path: "/ImageGeneration",
      name: "ImageGeneration",
      component: ImageGenerationView,
    },
    {
      path: "/404",
      name: "notFound",
      component: NotFound,
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ]
})

const public_pages = [
    "Home",
    "Landing",
    "notFound",
];

router.beforeEach(async (to, from, next) => {
  const { user } = useUserStore();
  const isAuthenticated = user && user.userId;
  document.title = to.meta.title || '오토라이팅 - AutoWriting';

  if(public_pages.indexOf(to.name) > -1) {
     next();
  } else {
      next();
  }

  window.scrollTo(0, 0)
})

export default router
