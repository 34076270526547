import moment from 'moment'
import _ from 'lodash';

export default {
    install: (app, options) => {
        app.config.globalProperties.$dateFormatter = (date, formatString) => {
            return moment(date).format(formatString || 'YYYY.MM.DD');
        }
        app.config.globalProperties.$dateFormatterYYYYMMDDOrHHmm = (date) => {
            const now = moment().format('YYYYMMDD');
            const newDate = moment(date).format('YYYYMMDD');

            if(Number(now) !== Number(newDate)) {
                return moment(date).format('YYYY.MM.DD');
            }  else {
                return moment(date).format('HH:mm');

            }
        }
        app.config.globalProperties.$numberFormatter = (number) => {
            return Intl.NumberFormat().format(number);
        }

        app.config.globalProperties.$pageListBoardNo = (list, totalCount, pageSize, currentPage) => { // 페이징처리된 목록의 게시물번호
            _.each(list, (el, index) => {
                el.boardNo = (totalCount - (pageSize*(currentPage-1))) - index;
            });
            return list || [];
        }

        app.config.globalProperties.$langKR = (langCode) => {
            switch (langCode) {
                case 'ko':
                    return `한국어(${langCode})`;
                case 'en':
                    return `영어(${langCode})`;
                default:
                    return langCode;
            }
        }
        app.config.globalProperties.$language = () => {
            const lang = navigator.language;
            const LANG_KO = 'ko';
            const LANG_EN = 'en';
            if(lang.startsWith(LANG_KO)) {
                return LANG_KO;
            } else {
                return LANG_EN;
            }
        }
    }
}