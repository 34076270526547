<script setup>
    import { ref, reactive, defineEmits } from 'vue'
    import Datepicker from 'vue3-datepicker'
    import { ko } from 'date-fns/locale'

    const emit = defineEmits(['change'])

    const date = ref();
    const datepicker = ref(null);

    const locale = reactive(ko)
    const inputFormat = ref('yyyy-MM-dd')

    const handleDate = (modelData) => {
        console.log(datepicker);
        date.value = modelData;
        emit('change', date.value);
    }
</script>

<template>
    <Datepicker
        v-model="date"
        :locale="locale"
        :weekStartsOn="0"
        :inputFormat="inputFormat"
        :clearable="true"
        @update:model-value="handleDate"
        :ref="datepicker"
    />
</template>


<style scoped>

</style>