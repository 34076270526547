<script setup >
import { computed, defineEmits } from "vue";
const props = defineProps({
    modelValue: { type: [Array, Boolean] },
    value: { type: String},
    label: { type: String },
    name: { type: String },
});
const emit = defineEmits(["update:modelValue"]);
const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});
</script>

<template>
    <label class="check-box">
        {{ label }}
        <input type="radio" :name="name" v-model="model" :value="value" />
        <span class="checkmark"></span>
    </label>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .check-box {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            /*&:checked ~ .checkmark {
                background-color: $blue_color;
            }*/
            &:checked ~ .checkmark:after {
                display: block;
            }
        }
        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: $border_line;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $blue_color;
            }
        }
    }
</style>
