<script setup>
    import { ref, computed, defineEmits, defineExpose } from "vue";
    const props = defineProps({
        modelValue: { type: String },
        type: { type: String },
        label: { type: String },
        placeholder: { type: String },
        msg: { type: String },
        msgColor: { type: String },
        maxLength: { type: String },
        disabled: { type: Boolean, default: false },
    });
    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const inputRef = ref(null)

    const focus = () => {
        inputRef.value.focus();
    }

    defineExpose({
        focus
    })
</script>

<template>
    <div>
        <label>
            <p v-if="label">{{ label }}</p>
            <input ref="inputRef" :type="type ? type : 'text'" v-model="model" :placeholder="placeholder" :maxlength="maxLength" :disabled="disabled">
        </label>
        <div v-if="msg" :style="{'color': msgColor || $color.labelcolor}"><small>{{ msg }}</small></div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/ui-components/input";

    label {
        p {
            font-size: 14px;
            line-height: 24px;
            color: $gray_color;
        }
    }
    input {
        &:disabled {
            color: $black_color;
            background: $gray-thin_color;
        }
    }
</style>