<script setup>
    import { ref, defineEmits } from "vue";

    const e1 = ref('')
    const e2 = ref('')

    const props = defineProps({
        email1: { type: String },
        email2: { type: String },
    });
    const emit = defineEmits(["update:email1", "update:email2"]);

    const onInputEmail1 = () => {
        emit('update:email1', e1.value);
    }

    const onInputEmail2 = () => {
        emit('update:email2', e2.value);
    }

    const onSelectedEmail = (value) => {
        if(!(value.indexOf('직접') > -1)) {
            e2.value = value;
            emit('update:email2', value);
        }
    }

</script>

<template>
    <div class="e-mail">
        <KSAInput v-model="e1" @input="onInputEmail1" />
        <span>@</span>
        <KSAInput v-model="e2" @input="onInputEmail2" />
        <KSASelectEmail @selectOption="onSelectedEmail" />
    </div>
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/theme";

    .e-mail {
        display: flex;
        align-items: center;
        div {
            width: 100%;
            &:last-child {
                margin-left: 10px;
            }
        }
        span {
            margin: 0 4px;
        }
    }
</style>