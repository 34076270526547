<script setup>
    import { defineProps, defineEmits } from 'vue'
    const { text, color, disabled } = defineProps(['text', 'color', 'disabled'])

    const emit = defineEmits(['click'])

    const emitClick = () => {
        emit('click')
    }
</script>

<template>
    <button @click="emitClick" :disabled="disabled" :style="color && {'border-color': color}">{{ text }}</button>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    button {
        height: 48px;
        border:1px solid blue;
        background: white;
        color: $blue_color;
        border: 1px solid $blue_color;
        cursor:pointer;
        border-radius: 4px;
        padding: 10px 20px;
        &.gray_button {
            color: $black_color;
            border: $border_line;
        }
        &.red_button {
            color: $red_color;
            border-color: $red_color;
        }
        &:disabled {
            background: $gray-light_color;
            color: $gray-regular_color;
            border: 0;
        }
    }
</style>