<script setup>
    import { computed } from 'vue'
    import { useRoute, RouterView } from 'vue-router'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import { useUserStore } from "../stores/user";

    const route = useRoute()
    const userStore = useUserStore()

</script>

<template>
    <div class="wrap">
        <div class="inner">
            <Header />
        </div>
    </div>
    <slot/>
    <Footer />
</template>


<style lang="scss">
    @import '../assets/scss/common';

</style>