<template>
    <Modal ref="baseModal">
        <div class="modal-inner">
          <h2>오토라이팅 상담 신청</h2>
            <div>
              <p>오토라이팅 도입관련 문의사항을 남겨주시면 내용 확인 후 연락드리겠습니다.</p>
              <dl>
                <dt>기업명</dt>
                <dd><input type="text" v-model="companyName" placeholder="기업명을 입력해주세요."/></dd>
              </dl>
              <dl>
                <dt>조직/팀</dt>
                <dd><input type="text" v-model="companyTeam" placeholder="조직/팀을 입력해주세요."/></dd>
              </dl>
              <dl>
                <dt>성명 (직급)</dt>
                <dd><input type="text" v-model="userName" placeholder="성함을 입력해주세요."/></dd>
              </dl>
              <dl>
                <dt>이메일주소</dt>
                <dd><input type="text" v-model="userEmail" placeholder="이메일을 입력해주세요."/></dd>
              </dl>
              <dl>
                <dt>휴대폰번호</dt>
                <dd><InputPhoneNum v-model="userPhone" label="" placeholder="휴대폰번호 11자리를 입력해주세요."/></dd>
              </dl>
              <dl>
                <dt>문의내용</dt>
                <dd><textarea v-model="contactContent"></textarea></dd>
              </dl>
                <!--
              <dl>
                <dt>참고자료(첨부)</dt>
                <dd><input type="file" /></dd>
              </dl>
              -->
            </div>
            <div class="btn-wrap">
              <SecondaryButton text="닫기" @click="close" />&nbsp;
              <PrimaryButton text="문의하기" @click="contact" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "../../components/ui-components/modal/Modal.vue";
import { ref } from "vue";
import consultationSummaryService from '../../services/consultationSummaryService'
import SecondaryButton from "../../components/ui-components/button/SecondaryButton.vue";

export default {
    name: "ContactModal",
    components: {
      SecondaryButton,
        Modal,
    },
    props: {
    },
    setup() {
        const baseModal = ref(null);
        const resolvePromise = ref(null);

        const companyName = ref("");
        const companyTeam = ref("");
        const userName = ref("");
        const userEmail = ref("");
        const userPhone = ref("");
        const contactContent = ref("");

        const show = () => {
            baseModal.value.open();

            return new Promise((resolve, _) => {
                // resolve 함수를 담아 외부에서 사용합니다.
                resolvePromise.value = resolve;
            });
        };

        const close = () => {
            baseModal.value.close();
            resolvePromise.value(false);
        };

        const contact = () => {

            let data = {
                companyName: companyName.value,
                companyTeam: companyTeam.value,
                userName: userName.value,
                userEmail: userEmail.value,
                userPhone: userPhone.value,
                contactContent: contactContent.value
            };

            consultationSummaryService.contactUs(data)
                .then((response) => {
                    companyName.value = "";
                    companyTeam.value = "";
                    userName.value = "";
                    userEmail.value = "";
                    userPhone.value = "";
                    contactContent.value = "";

                    baseModal.value.close();
                    resolvePromise.value(true);
                }).catch((error) => {
                    baseModal.value.close();
                    resolvePromise.value(false);
                    console.log(error);
                });
        };

        return {
            baseModal, show, close, contact,
            companyName, companyTeam, userName, userEmail, userPhone, contactContent
        };
    },
};
</script>


<style lang="scss" scoped>
@import "../../assets/scss/ui-components/input";

.backdrop .modal-container .modal-inner{
  width:600px;
  h2{
    text-align: center;
  }
  p{
    padding:10px 0;
    font-size:14px;
  }
  dl {
    padding:0;
    dt{
      font-size:12px;
      color: #72b1ec;
      font-weight:bold;
      padding-top:4px;
    }
    dd{
      input[type=text]{
        width:100%;
        border: 1px solid #E4E5F0;
      }
      textarea{
        width:100%;
        border: 1px solid #c1c1c1;
      }
    }
  }
}
    .btn-wrap {
        margin: 20px 0 0;
      text-align: center;
        button {
          padding:0 10px;
          height:36px;
          line-height:36px;
          display:inline-block;
        }
    }
</style>