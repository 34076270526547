<template>
    <div class="center">
        <img src="/src/assets/images/logo.png">
        <h1>페이지를 찾을 수 없습니다.</h1>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style lang="scss" scoped>

</style>