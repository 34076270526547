<script setup>
    import { defineProps, defineEmits } from 'vue'
    const { text, color, disabled } = defineProps(['text', 'color', 'disabled'])

    const emit = defineEmits(['click'])

    const emitClick = () => {
        emit('click')
    }
</script>

<template>
    <button @click="emitClick" :disabled="disabled" :style="{'color': color}">{{ text }}</button>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    button {
        height: 36px;
        border: none;
        background:none;
        cursor:pointer;
        color: $blue_color;
        border-radius: 4px;
        padding: 8px 16px;
        &.orange_button {
            color: $orange_color;
        }
        &:disabled {
            background: $gray-light_color;
            color: $gray-regular_color;
        }
    }
</style>